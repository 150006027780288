import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M3073 3313 c-45 -50 -99 -158 -114 -230 -15 -77 -6 -194 21 -268 27
-73 102 -181 133 -192 12 -4 -129 -10 -329 -13 l-351 -5 -42 -28 c-34 -23
-111 -121 -111 -142 0 -3 18 -5 40 -5 34 0 40 -3 40 -21 0 -19 -5 -21 -55 -17
-47 2 -56 0 -61 -15 -6 -18 -7 -17 -21 3 -8 11 -32 28 -54 37 -21 8 -39 19
-39 24 0 12 37 11 60 -1 15 -8 21 -6 30 11 15 28 10 32 -54 37 -45 4 -57 1
-75 -17 -38 -38 -21 -77 43 -98 17 -5 32 -15 34 -21 5 -15 -42 -16 -68 -2 -15
8 -21 6 -30 -7 -8 -14 -10 -14 -9 -3 4 42 -3 52 -53 74 -56 25 -52 43 8 32 27
-6 36 -3 41 10 8 22 -14 34 -68 34 -53 0 -79 -21 -79 -64 0 -23 8 -30 45 -47
25 -11 45 -25 45 -31 0 -10 -40 -7 -71 6 -24 11 -35 -25 -13 -41 21 -16 98
-17 125 -3 13 8 25 7 39 0 11 -6 40 -11 65 -12 25 0 45 -3 45 -7 0 -3 -9 -21
-20 -39 -26 -42 -26 -86 0 -112 20 -20 33 -20 1060 -20 1125 0 1070 -3 1137
54 28 23 153 220 207 323 19 37 14 73 -13 98 -16 13 -61 15 -360 16 -187 0
-341 1 -341 4 0 2 16 21 35 42 92 99 140 267 117 405 -16 94 -70 205 -122 252
-45 41 -52 42 -74 20 -24 -24 -20 -39 29 -105 116 -158 112 -354 -11 -506 -34
-41 -43 -76 -24 -88 28 -18 -4 -22 -142 -21 l-153 1 -3 143 c-3 141 -2 144 23
173 14 17 25 42 25 55 0 33 -42 74 -75 74 -14 0 -37 -11 -50 -25 -33 -32 -33
-74 0 -105 25 -24 25 -25 23 -169 l-3 -146 -145 -2 c-165 -3 -179 -1 -156 23
22 21 20 47 -4 69 -11 10 -33 39 -49 64 -91 144 -77 341 34 477 39 47 43 65
19 88 -24 25 -43 19 -81 -21z m-243 -795 c0 -40 3 -49 12 -40 7 7 29 12 50 12
55 0 88 -37 88 -97 0 -66 -39 -100 -105 -93 -11 1 -26 7 -32 12 -9 7 -13 7
-13 -1 0 -6 -13 -11 -30 -11 l-30 0 0 135 0 135 30 0 c29 0 30 -1 30 -52z
m-342 -28 c33 0 45 -15 33 -39 -10 -17 -16 -19 -31 -11 -23 12 -60 13 -60 1 0
-5 14 -14 32 -20 66 -23 88 -70 48 -106 -23 -21 -95 -24 -129 -5 -18 9 -19 14
-10 31 7 14 16 18 27 13 30 -12 72 -17 72 -7 0 5 -19 18 -42 29 -23 10 -46 27
-52 37 -22 36 37 94 82 81 8 -2 21 -4 30 -4z m728 -21 c23 -25 12 -34 -43 -34
-53 0 -70 -17 -59 -60 7 -26 9 -27 49 -21 23 3 49 2 57 -3 12 -8 12 -13 -3
-29 -36 -40 -124 -32 -153 13 -21 31 -20 89 0 120 29 44 118 52 152 14z m202
-14 c32 -38 30 -94 -5 -127 -44 -41 -116 -42 -157 -2 -36 37 -37 101 0 137 43
43 120 39 162 -8z m211 20 c11 -11 19 -10 46 6 26 16 37 17 63 8 37 -13 52
-49 52 -130 l0 -59 -35 0 -34 0 -3 67 c-3 58 -6 68 -22 71 -29 6 -46 -25 -46
-85 0 -52 -1 -53 -30 -53 -29 0 -29 1 -32 61 -2 45 -8 65 -20 72 -30 19 -48
-8 -48 -74 l0 -59 -35 0 -35 0 0 95 0 95 35 0 c19 0 35 -4 35 -9 0 -6 12 -3
28 4 28 15 58 12 81 -10z m399 14 c23 -8 34 -20 43 -47 l12 -37 9 33 c6 24 18
37 41 47 17 7 36 11 41 8 4 -3 33 -5 63 -4 l55 1 -4 -104 c-3 -93 -6 -107 -26
-129 -29 -31 -73 -40 -123 -27 -38 11 -46 23 -31 47 6 9 17 10 41 3 37 -10 71
1 71 24 0 10 -11 12 -42 9 -36 -4 -47 -1 -65 19 -11 12 -23 32 -26 43 -3 11
-5 -1 -6 -27 -1 -48 -1 -48 -36 -48 l-35 0 0 64 c0 58 -2 65 -22 72 -31 10
-48 -19 -48 -83 0 -52 0 -53 -30 -53 l-30 0 0 95 0 95 30 0 c17 0 30 -4 30 -9
0 -6 12 -3 28 5 32 17 23 16 60 3z m-1428 -52 c0 -64 19 -98 47 -81 14 8 19
26 21 73 l3 61 34 0 35 0 0 -95 c0 -83 -2 -95 -17 -96 -29 -2 -48 2 -51 12 -2
5 -14 2 -27 -7 -28 -19 -70 -10 -90 19 -10 14 -15 46 -15 94 l0 73 30 0 c30 0
30 -1 30 -53z m440 -112 c0 -27 -26 -40 -46 -24 -19 16 -18 36 3 47 24 14 43
4 43 -23z m817 9 c8 -21 -19 -46 -40 -38 -9 3 -17 17 -17 30 0 19 5 24 25 24
14 0 28 -7 32 -16z"/>
<path d="M2838 2414 c-15 -33 -1 -58 36 -62 20 -3 28 2 36 23 22 57 -47 95
-72 39z"/>
<path d="M3300 2421 c-15 -30 -12 -48 12 -65 39 -28 82 19 58 64 -14 26 -56
26 -70 1z"/>
<path d="M4160 2430 c-11 -11 -20 -24 -20 -30 0 -5 9 -19 20 -30 28 -28 60
-11 60 30 0 41 -32 58 -60 30z"/>
<path d="M3191 3225 c-115 -131 -117 -338 -4 -480 33 -41 75 -48 93 -15 9 17
5 31 -29 81 -84 125 -82 228 4 353 31 46 35 57 26 75 -18 32 -54 26 -90 -14z"/>
<path d="M3688 3235 c-10 -22 -7 -30 21 -65 99 -123 91 -254 -24 -407 -14 -19
6 -47 37 -51 20 -2 34 7 62 39 108 126 108 341 0 467 -42 50 -78 56 -96 17z"/>
<path d="M3316 3139 c-67 -79 -72 -211 -10 -300 30 -44 54 -55 84 -39 28 15
25 45 -7 87 -24 31 -28 46 -28 98 0 52 4 67 28 98 32 42 35 72 8 87 -30 16
-37 13 -75 -31z"/>
<path d="M3578 3169 c-25 -14 -22 -42 7 -85 45 -66 45 -132 -1 -199 -27 -40
-30 -65 -12 -83 30 -30 71 -2 111 73 15 30 21 58 21 110 0 75 -16 118 -62 168
-28 29 -38 32 -64 16z"/>
<path d="M1404 2565 c-10 -25 4 -45 30 -45 27 0 42 26 25 46 -15 19 -48 18
-55 -1z"/>
<path d="M530 2435 l0 -135 30 0 c29 0 29 1 32 61 3 65 19 88 52 76 12 -5 16
-20 16 -72 l0 -65 35 0 c31 0 35 3 36 28 0 25 1 25 9 6 11 -27 22 -34 52 -34
12 0 47 0 76 0 l52 0 0 80 c0 74 -2 81 -25 96 -18 12 -40 15 -80 12 -63 -5
-69 -9 -60 -37 5 -17 10 -19 33 -10 33 13 72 4 72 -16 0 -11 -12 -15 -43 -15
-44 0 -70 -13 -80 -40 -3 -8 -6 -1 -6 16 -2 85 -46 125 -109 98 l-32 -13 0 49
c0 49 -1 50 -30 50 l-30 0 0 -135z m328 -72 c-2 -11 -14 -19 -31 -21 -22 -3
-27 1 -27 17 0 17 6 21 31 21 24 0 30 -4 27 -17z"/>
<path d="M950 2360 l0 -130 30 0 c29 0 30 1 30 47 0 37 3 44 12 35 22 -22 86
-14 113 13 36 35 35 102 -1 139 -29 28 -90 36 -112 14 -9 -9 -12 -9 -12 0 0 7
-13 12 -30 12 l-30 0 0 -130z m141 58 c23 -43 -21 -89 -59 -62 -28 19 -28 59
-1 78 25 17 45 12 60 -16z"/>
<path d="M1180 2360 l0 -130 30 0 c29 0 30 1 30 47 0 37 3 44 12 35 19 -19 82
-14 106 8 62 58 24 170 -58 170 -20 0 -41 -5 -48 -12 -9 -9 -12 -9 -12 0 0 7
-13 12 -30 12 l-30 0 0 -130z m139 56 c18 -48 -19 -87 -57 -60 -33 23 -28 74
8 86 22 7 41 -2 49 -26z"/>
<path d="M1400 2394 l0 -95 33 3 c17 2 31 6 30 11 -2 4 -3 45 -3 92 l0 85 -30
0 -30 0 0 -96z"/>
<path d="M1490 2395 l0 -95 35 0 35 0 0 59 c0 64 15 87 50 76 17 -6 20 -15 20
-71 l0 -64 30 0 30 0 0 78 c0 64 -4 82 -18 95 -22 20 -83 22 -100 5 -9 -9 -12
-9 -12 0 0 7 -14 12 -35 12 l-35 0 0 -95z"/>
<path d="M1737 2470 c-49 -39 -44 -122 8 -156 29 -19 95 -21 124 -2 18 11 19
14 5 29 -12 14 -20 15 -44 6 -22 -8 -32 -8 -44 2 -29 24 -17 31 49 31 59 0 65
2 65 21 0 25 -18 60 -39 76 -26 20 -95 16 -124 -7z m91 -32 c20 -20 14 -28
-23 -28 -22 0 -35 5 -35 13 0 28 36 37 58 15z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
